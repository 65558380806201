<template>
    <div>
        <b-card no-body>
            <div class="m-2">
                <div>
                    <b-row>
                        <b-col cols="12">
                            <h1 class="float-left">{{ i18nT(`Suggestions`) }}</h1>
                            <b-button
                                variant="primary"
                                :to="{
                                  name: 'create-edit-suggestion',
                                  params: { id: 'new' },
                                }"
                                v-if="iCan('suggestions', 'write')"
                                class="float-right"
                            >
                                <feather-icon icon="PlusCircleIcon"/>
                                {{ i18nT(`Add new`) }}
                            </b-button>
                        </b-col>
                    </b-row>
                    <div class="mb-1">
            <span class="text-muted">{{ i18nT(`Showing`) }} {{ dataMeta.from }} {{ i18nT(`to`) }}
              {{ dataMeta.to }} {{ i18nT(`of`) }} {{ dataMeta.of }}
              {{ i18nT(`entries`) }}</span>
                    </div>
                    <b-row>
                        <b-col
                            cols="12"
                            md="6"
                            v-if="controlVisible"
                        >
                            <b-form-group :label="i18nT(`Type`)">
                                <v-select
                                    v-model="suggestionType"
                                    :options="suggestionTypes"
                                    :reduce="(e) => (e.value == '' ? '' : parseInt(e.value))"
                                    label="text"
                                    :clearable="false"
                                />
                            </b-form-group>
                        </b-col>
                        <b-col
                            cols="12"
                            md="6"
                            v-if="controlVisible"
                        >
                            <b-form-group :label="i18nT(`Activity`)">
                                <v-select
                                    v-model="suggestionActivity"
                                    :options="suggestionActivities"
                                    :reduce="(e) => e.value"
                                    label="text"
                                    :clearable="false"
                                />
                            </b-form-group>
                        </b-col>
                        <!--<b-col cols="12" md="4">
                          <b-form-group label="Order By">
                            <b-form-select v-model="orderBy" :options="suggestionOrderOptions" />
                          </b-form-group>
                      </b-col>-->
                    </b-row>
                </div>
                <!--<b-row>
                  <b-col
                    cols="12"
                  >
                    <label>{{i18nT(`Entries`)}}</label>


                  </b-col>
              </b-row>-->
            </div>

            <div>
                <div>
                    <div class="row mx-2">
                        <div class="col-12 col-md-6 col-sm 6 mb-1 pl-0">
                            <v-select
                                v-model="perPage"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="perPageOptions"
                                :clearable="false"
                                class="per-page-selector d-inline-block"
                            />
                            <b-dropdown
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                text="Actions"
                                size="md"
                                class="d-inline ml-1"
                                variant="outline-secondary"
                                v-if="selectedItems.length > 0"
                            >
                                <b-dropdown-item @click="onBulkCopy">
                                    <feather-icon icon="CopyIcon"/>
                                    <span class="align-middle ml-50">{{ i18nT(`Copy`) }}</span>
                                </b-dropdown-item>
                                <b-dropdown-item @click="onBulkDelete">
                                    <feather-icon
                                        icon="TrashIcon"
                                        color="red"
                                    />
                                    <span
                                        class="align-middle ml-50"
                                        style="color: red"
                                    >{{
                                            i18nT(`Delete`)
                                        }}</span>
                                </b-dropdown-item>
                            </b-dropdown>
                            <!-- <b-dropdown
                              size="md"
                              class="d-inline ml-1"
                              variant="outline-secondary"
                            >
                              <template v-slot:button-content>
                                <feather-icon icon="ExternalLinkIcon" />&nbsp;&nbsp;{{
                                  i18nT(`Export`)
                                }}
                              </template>
                              <b-dropdown-item @click="onExportExcel()">
                                <feather-icon icon="CopyIcon" />
                                <span class="align-middle ml-50">{{ i18nT(`Excel`) }}</span>
                              </b-dropdown-item>
                              <b-dropdown-item @click="onExportCsv()">
                                <feather-icon icon="CopyIcon" />
                                <span class="align-middle ml-50">{{ i18nT(`CSV`) }}</span>
                              </b-dropdown-item>
                            </b-dropdown> -->
                        </div>
                        <!-- search input -->
                        <div class="col-12 col-md-6 col-sm 6 mb-1 pr-0">
                            <div class="custom-search d-flex justify-content-end">
                                <b-form-group>
                                    <div
                                        role="group"
                                        class="input-group input-group-merge"
                                    >
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <feather-icon icon="SearchIcon"/>
                                            </div>
                                        </div>
                                        <b-form-input
                                            v-model="searchQuery"
                                            :placeholder="i18nT(`Search`)"
                                            type="text"
                                            class="d-inline-block"
                                        />
                                    </div>
                                </b-form-group>
                            </div>
                        </div>
                    </div>
                    <b-table
                        ref="refDocumentListTable"
                        :items="fetchSuggestions"
                        responsive
                        show-empty
                        primary-key="id"
                        :fields="computedColumns"
                        :sort-by.sync="orderBy"
                        :empty-html="emptyHtml"
                        :sort-desc.sync="isSortDirDesc"
                        class="position-relative"
                        @refreshed="onTableRefresh"
                        v-model="currentItems"
                    >
                        <template v-slot:head(checkbox)="data">
                            <span>{{ data.label.toUpperCase() }}</span>
                            <b-form-checkbox
                                class="d-inline-block pl-0"
                                @change="onSelectAll"
                                v-model="selectAllStatus"
                            >
                            </b-form-checkbox>
                        </template>

                        <template #head(action)="data">
                            <span>{{ data.label.toUpperCase() }}</span> 
                            <div class="sk-column-control">
                                <b-dropdown
                                    no-caret
                                    variant="button"
                                    toggle-class="p-0"
                                    :right="true"
                                    class="pr-1 pl-1 d-flex sk-column-filter"
                                    v-if="betaFeature"
                                    v-b-tooltip = "i18nT(`Customize columns`)"
                                >
                                    <template #button-content>
                                        <feather-icon
                                            icon="SettingsIcon"
                                            size="18"
                                            class="text-right"
                                        />
                                    </template>

                                    <div class="pl-1 pr-1" v-for="(field, index) in avalableFields" :key="'Fields - ' + index">
                                        <b-form-checkbox class="mb-1 mt-1 text-nowrap" v-model="field.visible">{{ i18nT(field.label) }}</b-form-checkbox>
                                    </div>
                                </b-dropdown>
                            </div>
                        </template>

                        <template #cell(checkbox)="data">
                            <b-form-checkbox
                                v-model="selectedItems"
                                :value="data.item.Id"
                            />
                        </template>

                        <template #cell(title)="data">

                            <div class="media">
                                <div class="media-aside align-self-start">
                                    <div class="media-aside align-self-start">
                                        <span
                                            class="b-avatar badge-light-primary rounded"
                                            style="width: 42px; height: 42px;"
                                        >
                                          <span class="b-avatar-custom">{{ data.item.Rating }}</span>
                                            <!---->
                                        </span>
                                    </div>
                                    <div>
                                        <feather-icon
                                            class="cursor-pointer text-success"
                                            icon="ChevronUpIcon"
                                            size="18"
                                            @click="onVoteSuggestion(data.item.Id, 1)"
                                        />
                                        <br/>
                                        <feather-icon
                                            class="cursor-pointer text-danger"
                                            icon="ChevronDownIcon"
                                            size="18"
                                            @click="onVoteSuggestion(data.item.Id, -1)"
                                        />
                                    </div>
                                </div>
                                <div class="media-body">
                                    <b-link
                                        :to="{ name: 'view-suggestion', params: { id: data.item.Id } }"
                                        class="font-weight-bold"
                                    >
                                        {{ data.item.Label }}
                                    </b-link>
                                    <p class="mb-0">{{ getTypeName(data.item.Type) }}</p>
                                </div>
                            </div>
                        </template>

                        <template #cell(type)="data">
                            <h5>{{ getTypeName(data.item.Type) }}</h5>
                        </template>

                        <template #cell(created)="data">
                            <h5>{{ data.item.CreatedAt | date }}</h5>
                        </template>

                        <template #cell(updated)="data">
                            <h5>{{ data.item.UpdatedAt | date }}</h5>
                        </template>

                        <template #cell(action)="data">
                            <b-dropdown
                                variant="link"
                                toggle-class="p-0"
                                no-caret
                                :right="$store.state.appConfig.isRTL"
                            >
                                <template #button-content>
                                    <feather-icon
                                        icon="MoreVerticalIcon"
                                        size="16"
                                        class="align-middle text-body"
                                    />
                                </template>
                                <b-dropdown-item :to="{
                                    name: 'create-edit-suggestion',
                                    params: { id: data.item.Id },
                                }">
                                    <feather-icon icon="EditIcon"/>
                                    <span class="align-middle ml-50">{{ i18nT(`Edit`) }}</span>
                                </b-dropdown-item>
                                <b-dropdown-item
                                    :to="{ name: 'view-suggestion', params: { id: data.item.Id } }"
                                >
                                    <feather-icon icon="EyeIcon"/>
                                    <span class="align-middle ml-50">{{ i18nT(`View`) }}</span>
                                </b-dropdown-item>
                                <b-dropdown-item @click="onCopy(data)">
                                    <feather-icon icon="CopyIcon"/>
                                    <span class="align-middle ml-50">{{ i18nT(`Copy`) }}</span>
                                </b-dropdown-item>
                                <b-dropdown-item @click="onDelete(data)">
                                    <feather-icon icon="TrashIcon" color="red"/>
                                    <span
                                        class="align-middle ml-50"
                                        style="color: red"
                                    >{{
                                            i18nT(`Delete`)
                                        }}</span>
                                </b-dropdown-item>
                            </b-dropdown>
                        </template>
                    </b-table>
                </div>
                <b-tabs
                    pills
                    align="center"
                    v-if="false"
                >
                    <b-tab active>
                        <template #title>
                            <feather-icon
                                icon="ListIcon"
                                size="24"
                                style="margin: 0"
                            />
                        </template>
                    </b-tab>

                    <b-tab>
                        <template
                            #title
                            style="text-align: center"
                        >
                            <feather-icon
                                icon="GridIcon"
                                size="24"
                                style="margin: 0"
                            />
                        </template>
                        <div class="task-container">
                            <b-row class="task-item">
                                <b-col
                                    cols="4"
                                    v-for="suggestion in qsuggestions"
                                    :key="suggestion.Id"
                                >
                                    <b-card style="border: 1px solid rgba(0, 0, 0, 0.07)">
                                        <b-card-body class="p-0">
                                            <b-row>
                                                <b-col>
                                                    <div
                                                        class="cv-card"
                                                        style="min-height: 180px"
                                                    >
                                                        <h5>{{ getTypeName(suggestion.Type) }}</h5>
                                                        <h3>{{ suggestion.Label }}</h3>
                                                        <p class="text-body">
                                                            {{ suggestion.Description }}
                                                        </p>
                                                        <h5>
                                                            Created At:
                                                            {{ suggestion.CreatedAt | date }}
                                                        </h5>
                                                        <h5>
                                                            Updated At:
                                                            {{ suggestion.UpdatedAt | date }}
                                                        </h5>
                                                    </div>
                                                </b-col>
                                            </b-row>
                                        </b-card-body>

                                        <b-card-footer class="
                        pb-0
                        pt-1
                        pr-1
                        pl-1
                        mt-2
                        ml-n2
                        mr-n2
                        text-top text-right
                      ">
                                            <b-dropdown
                                                variant="link"
                                                toggle-class="p-0"
                                                no-caret
                                            >
                                                <template #button-content>
                                                    <b-button>{{ i18nT(` Actions `) }}</b-button>
                                                </template>
                                                <b-dropdown-item :to="{
                                                        name: 'create-edit-suggestion',
                                                        params: { id: suggestion.Id },
                                                      }">
                                                    <feather-icon icon="EditIcon"/>
                                                    <span class="align-middle ml-50">
                                                        {{ i18nT(`Edit`)}}
                                                    </span>
                                                </b-dropdown-item>
                                                <b-dropdown-item @click="onCopy({ item: suggestion })">
                                                    <feather-icon icon="CopyIcon"/>
                                                    <span class="align-middle ml-50">{{
                                                            i18nT(`Copy`)
                                                        }}</span>
                                                </b-dropdown-item>
                                                <b-dropdown-item @click="onCopy({ item: suggestion })">
                                                    <feather-icon icon="CopyIcon"/>
                                                    <span class="align-middle ml-50">{{ i18nT(`View Results`) }}</span>
                                                </b-dropdown-item>
                                                <b-dropdown-item @click="onDelete({ item: suggestion })">
                                                    <feather-icon
                                                        icon="TrashIcon"
                                                        color="red"
                                                    />
                                                    <span
                                                        class="align-middle ml-50"
                                                        style="color: red"
                                                    >{{
                                                            i18nT(`Delete`)
                                                        }}</span>
                                                </b-dropdown-item>
                                            </b-dropdown>
                                        </b-card-footer>
                                    </b-card>
                                </b-col>
                            </b-row>
                        </div>
                    </b-tab>
                </b-tabs>

                <div class="mx-1 mb-2">
                    <b-row>
                        <b-col
                            cols="12"
                            sm="6"
                            class="
                d-flex
                align-items-center
                justify-content-center justify-content-sm-start
              "
                        >
                            <b-form-checkbox
                                class="d-inline-block mx-1"
                                @change="onSelectAll"
                                v-model="selectAllStatus"
                            >
                                {{ i18nT(`All`) }}
                            </b-form-checkbox>
                            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
                {{ dataMeta.of }} entries</span>
                        </b-col>
                        <!-- Pagination -->
                        <b-col
                            cols="12"
                            sm="6"
                            class="
                d-flex
                align-items-center
                justify-content-center justify-content-sm-end
              "
                        >
                            <b-pagination
                                v-model="currentPage"
                                :total-rows="totalDocuments"
                                :per-page="perPage"
                                first-number
                                last-number
                                class="mb-0 mt-1 mt-sm-0"
                                prev-class="prev-item"
                                next-class="next-item"
                            >
                                <template #prev-text>
                                    <feather-icon
                                        icon="ChevronLeftIcon"
                                        size="18"
                                    />
                                </template>
                                <template #next-text>
                                    <feather-icon
                                        icon="ChevronRightIcon"
                                        size="18"
                                    />
                                </template>
                            </b-pagination>
                        </b-col>
                    </b-row>
                </div>
            </div>
        </b-card>
    </div>
</template>

<script>
import {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BButton,
    BTable,
    BLink,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTabs,
    BTab,
    // BListGroup, BListGroupItem,
    BCardFooter,
    BCardBody,
    BFormCheckbox,
    BFormInput,
    VBTooltip
    // VBModal
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import useSuggestionList from './useSuggestionList'
import {avatarText} from '@core/utils/filter'
import {
    dictToSelectArrayWithTitle,
    dictToSelectArray
} from '@core/utils/utils'
import router from '@/router'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useAuth from "@/auth/useAuth";

// import _ from 'lodash'

export default {
    components: {
        BRow,
        BCol,
        BFormGroup,
        BCard,
        BButton,
        BTable,
        BLink,
        BDropdown,
        BDropdownItem,
        BPagination,
        BTabs,
        BTab,
        // BListGroup,
        // BListGroupItem,
        BCardFooter,
        BCardBody,
        vSelect,
        BFormCheckbox,
        BFormInput,
        VBTooltip
        // VBModal
    },
    directives: {
        Ripple,
        'b-tooltip': VBTooltip,
        // 'b-modal': VBModal,
    },
    data() {
        return {
            pagination: {},
            selectedItems: [],
            currentItems: [],
            selectAllStatus: false,
            suggestionTypes: [{value: '', text: this.i18nT(`All`)}],
            suggestionActivities: [{value: '', text: this.i18nT(`All`)}],
            suggestionOrderOptions: [],
            avalableFields: [
              {label: this.i18nT(`Type`), key: 'type', visible: true},
              {label: this.i18nT(`Created`), key: 'created', visible: true},
              {label: this.i18nT(`Updated`), key: 'updated', visible: true},
            ],
            controlVisible: true,
            listenerAdded: false,
        }
    },
    created() {
        this.getSuggestionTypes()
        this.getSuggestionActivities()
        this.getSuggestionOrderOptions()
        let storePreferences = useAuth.getColumnPreferences('suggestions')
          if(storePreferences) {
            this.avalableFields = storePreferences
        }
        this.fetchSuggestions(null, this.determineVisibility);
    },
    methods: {
        determineVisibility(invoiceArray) {
          if(invoiceArray.length===0){
            this.controlVisible = false;
          }
        },
        getSuggestionTypes() {
            this.$http.get(`suggestions/suggestionTypes`).then(({data}) => {
                this.suggestionTypes = dictToSelectArray(data.data)
                console.log(this.suggestionTypes)
            })
        },
        getSuggestionActivities() {
            this.$http.get(`suggestions/activityFilters`).then(({data}) => {
                this.suggestionActivities = dictToSelectArray(data.data)
                console.log('suggestionActivities: ', this.suggestionActivities)
            })
        },
        getSuggestionOrderOptions() {
            this.$http.get(`suggestions/orderFilters`).then(({data}) => {
                this.suggestionOrderOptions = dictToSelectArray(data.data)
            })
        },
        removeLabel(label) {
            console.log(label)
        },
        getLabels(labelArray) {
            return dictToSelectArrayWithTitle(labelArray)
        },
        getTypeName(typeId) {
            const suggestionType = this.suggestionTypes.find(
                type => type.value == typeId
            )
            return suggestionType ? suggestionType.text : ''
        },

        getAvatarName(name) {
            if (name) {
                return name
                    .split(' ')
                    .map(namePart => {
                        return namePart[0]
                    })
                    .join('')
                    .toUpperCase()
            } else {
                return ''
            }
        },
        onTableRefresh() {
            this.selectedItems = []
        },
        onCopy(suggestion) {
            this.$http
                .post(`suggestions/copy?suggestion_id=${suggestion.item.Id}`)
                .then(() => {
                    this.refetchData()
                })
        },
        onDelete(suggestion) {
            this.$swal({
                title: this.i18nT(`Are you sure you want to delete this entry?`),
                text: this.i18nT(`You won't be able to revert this!`),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: this.i18nT(`Confirm`),
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1'
                },
                buttonsStyling: false
            }).then(result => {
                if (result.value) {
                    this.$http
                        .delete(`suggestions?id=${suggestion.item.Id}`)
                        .then(() => {
                            this.$swal({
                                icon: 'success',
                                title: this.i18nT(`Deleted!`),
                                text: this.i18nT(`The suggestion was successfully deleted.`),
                                customClass: {
                                    confirmButton: 'btn btn-success'
                                }
                            })
                            this.refetchData()
                        }).catch(err => {
                            this.$swal({
                                icon: 'error',
                                title: 'Error!',
                                text: this.errorMessage(err),
                                customClass: {
                                    confirmButton: 'btn btn-success'
                                }
                            })
                        })
                }
            })
        },
        onVoteSuggestion(id, vote) {
            const formData = new FormData()

            formData.append('id', id)
            formData.append('vote', vote)
            this.$http
                .post('suggestions/vote', formData)
                .then(res => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: this.successMessage(res),
                            icon: 'InfoIcon',
                            variant: 'success'
                        }
                    })

                    this.refetchData()
                })
                .catch(err => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: this.errorMessage(err),
                            icon: 'InfoIcon',
                            variant: 'danger'
                        }
                    })
                })
        },
        onBulkCopy() {
            this.$http
                .post(`suggestions/copy?suggestion_id=${this.selectedItems.join(',')}`)
                .then(() => {
                    this.selectedItems = []
                    this.selectAllStatus = false
                    this.refetchData()
                })
        },
        onBulkDelete() {
            this.$swal({
                title: this.i18nT(`Are you sure?`),
                text: this.i18nT(`You won't be able to revert this!`),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: this.i18nT(`Yes, delete!`),
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1'
                },
                buttonsStyling: false
            }).then(result => {
                if (result.value) {
                    this.$http
                        .delete(`suggestions?id=${this.selectedItems.join(',')}`)
                        .then(() => {
                            this.$swal({
                                icon: 'success',
                                title: this.i18nT(`Deleted!`),
                                text: this.i18nT(`Suggestions successfully deleted.`),
                                customClass: {
                                    confirmButton: 'btn btn-success'
                                }
                            })
                            this.selectedItems = []
                            this.selectAllStatus = false
                            this.listenerAdded = false;
                            this.refetchData()
                        }).catch(err => {
                            this.$swal({
                                icon: 'error',
                                title: 'Error!',
                                text: this.errorMessage(err),
                                customClass: {
                                    confirmButton: 'btn btn-success'
                                }
                            })
                            this.selectedItems = []
                            this.selectAllStatus = false
                            this.listenerAdded = false;
                            this.refetchData()
                    })
                }
            })
        },
        onSelectAll(val) {
            if (val) {
                this.selectedItems = this.currentItems.map(item => item.Id)
            } else {
                this.selectedItems = []
            }
        },
        onPutInFolder(data) {
            console.log(data)
            // this.$bvModal.show('modal-pipeline');
        },
        onExportExcel() {
        },
        onExportCsv() {
        },
    },
    setup() {
        const {
            fetchSuggestions,
            tableColumns,
            perPage,
            currentPage,
            totalDocuments,
            dataMeta,
            perPageOptions,
            searchQuery,
            orderBy,
            isSortDirDesc,
            refDocumentListTable,
            suggestionType,
            suggestionActivity,
            group,
            qsuggestions,
            refetchData
        } = useSuggestionList(router.currentRoute.meta.template === 1)

        return {
            fetchSuggestions,
            tableColumns,
            perPage,
            currentPage,
            totalDocuments,
            dataMeta,
            perPageOptions,
            searchQuery,
            orderBy,
            isSortDirDesc,
            refDocumentListTable,
            suggestionType,
            suggestionActivity,
            group,
            avatarText,
            qsuggestions,
            refetchData
        }
    },
    computed: {
        emptyHtml() {
            return "<img src='" + require('@/assets/images/pages/error.svg') + "' alt='Error page' class='img-fluid' width='300'><br/><br/><h4>"
                + this.i18nT(`Here, you can create and manage your suggestions. No suggestions have been created yet.`) + "</h4>"
                +"<button class='btn btn-outline-secondary mt-2'  id='suggestion-btn'>" + this.i18nT(`Create suggestion`) + "</button>"
        },
        computedColumns() {

          let col = [...this.tableColumns]

            for(let i = 0; i < this.avalableFields.length; i++) {
              if(!this.avalableFields[i].visible) {
                col = col.filter(c => c.key !== this.avalableFields[i].key)
              }
            }
            useAuth.setColumnPreferences('suggestions', this.avalableFields);

            return col
        },

    },
    updated() {
        const suggestionBtn = document.querySelector('#suggestion-btn')
           if(suggestionBtn && !this.listenerAdded) {
                    suggestionBtn.addEventListener('click', () => {
                        this.$router.push({
                            name: 'create-edit-suggestion',
                            params: { id: 'new' },
                        })
                    })
               this.listenerAdded = true;
                }
    },

}
</script>

<style lang="scss">
.task-container {
    .task-item {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .line-item {
        margin-top: 4px;
    }
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
@import '@core/scss/vue/pages/page-blog.scss';

.section-title {
    font-size: 11px;
}

.document-name {
    display: inline;
    line-height: 32px;
}

.document-actions-bar {
    float: right;
}

.ql-editor {
    min-height: 200px;
}

.tag-container {
    display: inline-block;
    background: #e6e6e6;
    color: #626262;
    padding: 3px 9px;
    font-size: 11px;
    font-weight: 600;
    margin: 4px;
    border-radius: 5px;

    .handler {
        cursor: pointer;
    }
}

.cv-card {
    min-height: 180px;

    span {
        font-size: 12px;
    }
}
</style>
