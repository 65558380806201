import { ref, watch, computed } from '@vue/composition-api';
import axios from '@axios';
import useAuth from '@/auth/useAuth';
import { useUtils as useI18nUtils } from '@core/libs/i18n';
const { i18n } = useI18nUtils();
function i18nT(str){
    return i18n(str)
  };
export default function useSuggestionList(isTemplate) {
  const refDocumentListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: 'checkbox', label: '', sortable: false, thClass: 'tbl-chk', tdClass: 'tbl-chk' },
    { key: 'title', label: i18nT(`Title`), sortable: true, thClass: 'pl-1', tdClass: 'pl-1' },
    { key: 'type', label: i18nT(`Type`), sortable: true },
    { key: 'created', label: i18nT(`Created`), sortable: true },
    { key: 'updated', label: i18nT(`Updated`), sortable: true },
    {
      key: 'action',
      label: i18nT(`Actions`),
      thClass: 'tbl-actions',
      tdClass: 'sticky-column',
      stickyColumn: true,
      sortable: false
    },
  ];
  const perPage = ref(10);
  const totalDocuments = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref('');
  const orderBy = ref('');
  const isSortDirDesc = ref(true);
  const suggestionType = ref('');
  const suggestionActivity = ref('');
  const group = ref(null);
  const suggestions = ref([]);

  const dataMeta = computed(() => {
    const localItemsCount = refDocumentListTable.value ?
      refDocumentListTable.value.localItems.length :
      0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalDocuments.value,
    };
  });

  const refetchData = () => {
    refDocumentListTable.value.refresh();
  };

  watch(
    [
      currentPage,
      perPage,
      searchQuery,
      suggestionType,
      suggestionActivity,
      group,
      isSortDirDesc,
      orderBy,
    ],
    () => {
      refetchData();
    }
  );

  const fetchSuggestions = (ctx, callback) => {
    const curWorkspace = useAuth.getCurWorkspace();

    const params = {
      page: currentPage.value,
      show_per_page: perPage.value,
      order_by: orderBy.value,
      // sort_order: isSortDirDesc.value ? 'DESC' : 'ASC',
      company_id: curWorkspace.Id,
    };

    if (isTemplate) {
      params.templates = 1;
    }

    if (suggestionType.value != null && suggestionType.value !== '') {
      params['filters[type]'] = suggestionType.value;
    }
    if (suggestionActivity.value != null && suggestionActivity.value !== '') {
      params['filters[activity]'] = suggestionActivity.value;
    }
    if (group.value != null) {
      params['filters[group]'] = group.value;
    }

    axios
      .get(`suggestions`, {
        params,
      })
      .then(({ data }) => {
        console.log(data);
        suggestions.value = data.data.suggestions;
        callback(data.data.suggestions);
        totalDocuments.value = data.data.pagination.records_count;
      });
  };

  return {
    fetchSuggestions,
    tableColumns,
    perPage,
    currentPage,
    dataMeta,
    perPageOptions,
    searchQuery,
    orderBy,
    isSortDirDesc,
    refDocumentListTable,
    totalDocuments,
    suggestionType,
    suggestionActivity,
    group,
    suggestions,
    refetchData,
  };
}